import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikValues } from 'formik';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addAuth } from '../../Auth/actions/auth.action';
import { addCampaing } from '../../Auth/actions/campaing.action';
import { IAuth } from '../../Auth/interfaces/auth.interface';
import { ICampaing } from '../../Auth/interfaces/campaing.interface';
import { IReducers } from '../../Auth/store/store';
import { LoginForm } from '../../components/Forms';
import { INITIAL_VALUES } from '../../components/Forms/LoginForm/constants/initial-values-form.constant';
import validationLoginSchema from '../../components/Forms/LoginForm/constants/validationLoginSchema';
import LanguageButtons from '../../components/LanguageButtons';
import Logo from '../../components/Logo';
import { makeLogin } from '../../utils/api/POST';
import { getUserInfo } from '../../utils/api/GET';
import { SideImage } from './SideImage';
import {
  Container,
  StyledGridContainer,
  StyledGridItem,
  StyledLogoBox,
} from './styles';

const Login: React.FC = (): JSX.Element => {
  const { is_staff, name, access }: IAuth = useSelector(
    (state: IReducers) => state.auth
  );
  const [success, setSuccess] = useState<string>(access ?? '');
  const [isStaff, setIsStaff] = useState<boolean>(is_staff ?? false);
  const [userName, setUserName] = useState<string>(name ?? '');
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const setAuth = (auth: IAuth): void => {
    dispatch(addAuth(auth));
  };

  const setCampaingValue = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const handleLogin = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const { email, password } = values;
    makeLogin(email, password)
      .then(res => {
        const decoded: any = jwtDecode(res?.data.access ?? '');
        const { user_id } = decoded;
        const { access, name, sms_bi_url, is_master, groups } = res?.data;
        let isMain = false;
        let hasWhatsapp = false;
        let hasEmail = false;
        let hasRCS = false;
        let hasAI = false;
        let hasData = false;
        let hasJourney = false;
        let hasDivzeroJourney = false;
        if (groups.length > 0 && groups.includes('Main')) {
          isMain = true;
        }
        if (groups.length > 0 && groups.includes('Whatsapp')) {
          hasWhatsapp = true;
        }
        if (groups.length > 0 && groups.includes('EMAIL')) {
          hasEmail = true;
        }
        if (groups.length > 0 && groups.includes('RCS')) {
          hasRCS = true;
        }
        if (groups.length > 0 && groups.includes('AI')) {
          hasAI = true;
        }
        if (groups.length > 0 && groups.includes('DATA')) {
          hasData = true;
        }
        if (groups.length > 0 && groups.includes('JOURNEY')) {
          hasJourney = true;
        }
        if (groups.length > 0 && groups.includes('DIVZERO_JOURNEY')) {
          hasDivzeroJourney = true;
        }
        if (res?.data.is_staff === true) {
          setIsStaff(res?.data.is_staff);
        }
        setUserName(name);
        setSuccess(access);
        setAuth({
          user_id: user_id,
          sms_bi_url: sms_bi_url,
          is_master: is_master,
          is_main: isMain,
          hasWhatsapp: hasWhatsapp,
          hasEmail: hasEmail,
          hasRCS: hasRCS,
          hasAI: hasAI,
          hasData: hasData,
          hasJourney: hasJourney,
          hasDivzeroJourney: hasDivzeroJourney
        });
        getUserInfo(user_id).then(res => {
          setCampaingValue({
            email_quota: res?.data.email_quota,
          });
        });
      })
      .catch((err: AxiosError) => {
        if (err) {
          setSubmitting(false);
          enqueueSnackbar(t('login.validation.invalid-email-or-password'), {
            variant: 'error',
            autoHideDuration: 2500,
          });
        }
      });
  };

  useEffect(() => {
    if (success?.length) {
      setAuth({ access: success, is_staff: isStaff, name: userName });

      history.push('/home');
    }
  }, [success, setSuccess]);

  return (
    <Container>
      <StyledGridContainer container>
        <StyledGridItem item xl={6} lg={6}>
          <Box position="absolute" top="16px" left="16px">
            <LanguageButtons />
          </Box>
          <StyledLogoBox>{/* <Logo /> */}</StyledLogoBox>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationLoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleLogin(values, setSubmitting);
            }}
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <LoginForm />
              </form>
            )}
          </Formik>
        </StyledGridItem>
        <StyledGridItem item xl={6} lg={6}>
          <SideImage />
        </StyledGridItem>
      </StyledGridContainer>
    </Container>
  );
};

export default Login;
