import { AxiosResponse } from 'axios';
import { request } from '../BASE_REQUEST';

export const getDivzeroJourneys = async (
    requestURL: string
): Promise<AxiosResponse<any> | undefined> => {
    try {
        const response = await request({
            url: `${requestURL}`,
            method: 'GET',
        });
        return response;
    } catch (error) {
        Promise.reject(error);
    }
};
