export const BASE_URL = process.env.REACT_APP_NEXUS_API_HOST;
export const BASE_WHATSAPP_URL = process.env.REACT_APP_NEXUS_WHATSAPP_API_HOST;
export const BASE_EMAIL_URL = process.env.REACT_APP_NEXUS_EMAIL_API_HOST;
export const BASE_RCS_URL = process.env.REACT_APP_NEXUS_RCS_API_HOST;
export const BASE_AI_URL = process.env.REACT_APP_NEXUS_AI_API_HOST;
export const BASE_DATA_URL = process.env.REACT_APP_NEXUS_DATA_API_HOST;
export const BASE_SHURIKEN_URL = process.env.REACT_APP_SHURIKEN_API_HOST;
export const BASE_CHAT_URL = process.env.REACT_APP_NEXUS_CHAT_API_HOST;
export const BASE_JOURNEY_URL = process.env.REACT_APP_NEXUS_JOURNEY;

//export const BASE_URL = 'http://localhost:8000'
